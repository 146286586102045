import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';

function Footer() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    return (
        <div className="Footer">
            <div className="language">
                <button
                    className={`language__button ${
                        i18n.language === 'en' && 'language__button_active'
                    }`}
                    onClick={() => changeLanguage('en')}
                >
                    EN
                </button>
                <button
                    className={`language__button ${
                        i18n.language === 'ru' && 'language__button_active'
                    }`}
                    onClick={() => changeLanguage('ru')}
                >
                    RU
                </button>
            </div>
            <div className="navigate-wrapper">
                <nav className="nav-footer">
                    <a href={`${process.env.PUBLIC_URL}/pdf/politics.pdf`} data-name="privacy-policy">
                        {t('footer.privacyPolicy')}
                    </a>
                    <a href={`${process.env.PUBLIC_URL}/pdf/cookie.pdf`} data-name="cookie-policy">
                        {t('footer.cookiePolicy')}
                    </a>
                    <a href={`${process.env.PUBLIC_URL}/pdf/conditions.pdf`} data-name="terms-of-service">
                        {t('footer.termsOfService')}
                    </a>
                </nav>
            </div>
        </div>
    );
}

export default Footer;
