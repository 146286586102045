import React from 'react';
import { useTranslation } from 'react-i18next';
import './OrderTable.css'
const OrderTable = ({ allOrders }) => {
  const { t } = useTranslation();

  return (
    <div className='OrderTable-wrapper'>
      <h1>{t('orderTable.all_orders')}</h1>
      <table>
        <thead>
          <tr>
            <th>{t('orderTable.order_id')}</th>
            <th>{t('orderTable.status')}</th>
            <th>{t('orderTable.customer_name')}</th>
            <th>{t('orderTable.customer_phone')}</th>
          </tr>
        </thead>
        <tbody>
          {allOrders.map((order, index) => (
            <tr key={order.id} data-name={`order-${index}`}>
              <td>{order.id}</td>
              <td>{order.status}</td>
              <td>{order.customerName}</td>
              <td>{order.customerPhone}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderTable;
