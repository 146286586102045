import React from 'react'
import { useTranslation } from 'react-i18next'
import { Popup } from 'components'

import './NotificationPopup.css'

function NotificationPopup({ newOrderId, onClose, dataName }) {
  const { t } = useTranslation()

  return (
    <Popup isOpened={newOrderId} onClose={onClose} dataName={dataName}>
      <h3 className="notification-popup__text">{`${t(
        'createOrderSuccessPopup.title'
      )}!`}</h3>
      <span className="notification-popup__text">{`${t(
        'createOrderSuccessPopup.trackingCode'
      )}: ${newOrderId}`}</span>
      <button
        className="notification-popup__button"
        data-name={`${dataName}-ok-button`}
        type="button"
        onClick={onClose}
      >
        {t('createOrderSuccessPopup.submitButton')}
      </button>
    </Popup>
  )
}

export default NotificationPopup
