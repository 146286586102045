import {
    GET_ALL_ORDERS,
    REQUEST,
    SUCCESS,
    FAILURE
  } from '../actions/actionTypes';
  
  const initialState = {
    allOrders: [],
    loading: false,
    error: null,
  };
  
  const allOrdersReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_ORDERS + REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case GET_ALL_ORDERS + SUCCESS:
        return {
          ...state,
          loading: false,
          allOrders: action.payload,
        };
      case GET_ALL_ORDERS + FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default allOrdersReducer;