import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux'
import { resetError } from 'redux/actions'
import { requestErrorMessages } from 'utils/constants'
import { Popup } from 'components'

import './ErrorPopup.css'

function ErrorPopup({ errorKey, dataName }) {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [error, setError] = useState(null)

  useEffect(() => {
    if (errorKey) {
      setError(requestErrorMessages(t)[errorKey]())
    }
  }, [i18n.language, t, errorKey])

  const handleClosePopup = () => {
    setError(null)
    dispatch(resetError())
  }

  if (!errorKey) {
    return null
  }

  return (
    <Popup dataName={dataName} isOpened={errorKey} onClose={handleClosePopup}>
      <span className="error-popup__title">{error?.title}</span>
      {error?.description && (
        <span className="error-popup__description">{error?.description}</span>
      )}
    </Popup>
  )
}

export default ErrorPopup
