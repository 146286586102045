import { LOGIN, CREATE_ORDER, GET_ORDER_BY_ID, FAILURE } from './actionTypes'
import { requestErrorsKeys } from 'utils/constants'

const getErrorMessages = ({ errorCode = 500, action }) => {
  if (errorCode === 500) {
    return requestErrorsKeys.serverError
  }

  switch (action) {
    case LOGIN:
      return requestErrorsKeys.invalidAuthUserData
    case CREATE_ORDER:
      return requestErrorsKeys.orderCreationFailure
    case GET_ORDER_BY_ID:
      return requestErrorsKeys.orderNotFound
    default:
      return requestErrorsKeys.otherError
  }
}

const handleError = ({ errorCode = 500, action }) => {
  return {
    type: action + FAILURE,
    payload: getErrorMessages({ errorCode, action }),
  }
}

export default handleError
