import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { login } from 'redux/actions'
import { currentUserSelector } from 'redux/selectors'
import { useValidation } from 'utils/hooks'
import { ErrorPopup, FormError, Header, Preloader } from 'components'

import './LoginPage.css'

const initialState = { username: '', password: '' }

const LoginPage = () => {
  const dispatch = useDispatch()
  const { errorKey, loading } = useSelector(currentUserSelector)
  const {
    values: { username, password },
    errors,
    isFormValid,
    handleChange,
  } = useValidation({
    values: initialState,
    isFormValid: true,
  })

  const { t } = useTranslation()

  const handleLogin = async (event) => {
    event.preventDefault()
    dispatch(login({ username, password }))
  }

  if (loading) {
    return (
      <div className="login-page">
        <Header hideInfo />
        <Preloader />
      </div>
    )
  }

  return (
    <div className="login-page">
      <Header hideInfo />
      <main className="login">
        <form className="login__form form" onSubmit={handleLogin}>
          <fieldset className="login__fieldset fieldset">
            <input
              className={`input login__input ${
                errors.username && 'input_invalid'
              }`}
              id="username"
              type="text"
              name="username"
              placeholder={t('loginPage.form.username')}
              minLength="2"
              maxLength="30"
              required
              value={username}
              onChange={handleChange}
              data-name={'username-input'}
            />
            <FormError
              message={errors.username}
              dataName={'username-input-error'}
            />
          </fieldset>

          <fieldset className="login__fieldset fieldset">
            <input
              className={`input login__input ${
                errors.password && 'input_invalid'
              }`}
              id="password"
              type="password"
              name="password"
              placeholder={t('loginPage.form.password')}
              minLength="8"
              maxLength="30"
              required
              value={password}
              onChange={handleChange}
              data-name={'password-input'}
              autoComplete="off"
            />
            <FormError
              message={errors.password}
              dataName={'username-input-error'}
            />
          </fieldset>

          <button
            className="button login__button"
            type="submit"
            data-name={'signIn-button'}
            disabled={!isFormValid}
          >
            {t('loginPage.form.submitButton')}
          </button>
        </form>
        <ErrorPopup dataName={'authorizationError-popup'} errorKey={errorKey} />
      </main>
    </div>
  )
}

export default LoginPage
