import {
  CREATE_ORDER,
  REQUEST,
  SUCCESS,
  FAILURE,
  CLEAR_ORDER,
  RESET_ERROR,
} from '../actions/actionTypes'

const initialState = {
  order: null,
  loading: false,
  errorKey: null,
}

const newOrder = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORDER + REQUEST:
      return { ...state, loading: true, errorKey: null }
    case CREATE_ORDER + SUCCESS:
      return { ...state, order: action.payload, loading: false, errorKey: null }
    case CREATE_ORDER + FAILURE:
      return { ...state, order: null, loading: false, errorKey: action.payload }
    case CLEAR_ORDER:
      return initialState
    case RESET_ERROR:
      return { ...state, errorKey: null }
    default:
      return state
  }
}

export default newOrder
