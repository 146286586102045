import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createOrder, resetOrder } from 'redux/actions';
import { newOrderSelector } from 'redux/selectors';
import {useValidation} from 'utils/hooks/indexTS';
import { ErrorPopup, FormError, Header, NotificationPopup } from 'components';

import './NewOrderPage.css'

import OrderRequest from 'interfaces/OrderRequest';

const initialOrderState: OrderRequest = {
  name: '',
  phone: '',
  comment: '',
};

const NewOrderPageTS = () => {
  const dispatch = useDispatch();
  const { order: newOrderFromApi, errorKey } = useSelector(newOrderSelector);

  const initialState = {
    initialValues: initialOrderState,
    isFormValid: true,
  };


  const {
    values,
    handleChange,
    errors,
    isFormValid,
    resetForm,
  } = useValidation<OrderRequest>(initialState);

  const { t } = useTranslation();

  const handleCreateOrder = async (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(createOrder(values));
  };

  const handleClosePopup = () => {
    dispatch(resetOrder());
    resetForm(initialOrderState);
  };

  return (
    <div className="new-order-page">
      <Header />
      <main className="new-order">
        <h2 className="new-order__title">{t('createOrderPage.form.title')}</h2>
        <form className="new-order__form" onSubmit={handleCreateOrder}>
          <fieldset className="new-order__fieldset fieldset">
            <input
              className={`input new-order__input ${
                errors.name && 'input_invalid'
              }`}
              id="name"
              type="text"
              name="name"
              placeholder={t('createOrderPage.form.name') as string}
              minLength={2}
              maxLength={12}
              required
              value={values.name}
              onChange={handleChange}
              data-name={'username-input'}
            />
            <FormError
              message={errors.name}
              dataName={'username-input-error'}
            />
          </fieldset>

          <fieldset className="new-order__fieldset fieldset">
            <input
              className={`input new-order__input ${
                errors.phone && 'input_invalid'
              }`}
              id="phone"
              type="tel"
              name="phone"
              placeholder={t('createOrderPage.form.phone') as string}
              minLength={6}
              maxLength={12}
              required
              value={values.phone}
              onChange={handleChange}
              data-name={'phone-input'}
            />
            <FormError message={errors.phone} dataName={'phone-input-error'} />
          </fieldset>

          <fieldset className="new-order__fieldset fieldset">
            <input
              className={`input new-order__input ${
                errors.comment && 'input_invalid'
              }`}
              id="comment"
              type="text"
              name="comment"
              placeholder={t('createOrderPage.form.comment') as string}
              maxLength={20}
              value={values.comment}
              onChange={handleChange}
              data-name={'comment-input'}
            />
            <FormError
              message={errors.comment}
              dataName={'comment-input-error'}
            />
          </fieldset>

          <button
            className="button new-order__button"
            type="submit"
            data-name={'createOrder-button'}
            disabled={!isFormValid}
          >
            {t('createOrderPage.form.submitButton')}
          </button>
        </form>
        <NotificationPopup
          dataName={'orderSuccessfullyCreated-popup'}
          newOrderId={newOrderFromApi?.id}
          onClose={handleClosePopup}
        />
        <ErrorPopup errorKey={errorKey} dataName={'orderCreationError-popup'} />
      </main>
    </div>
  );
};

export default NewOrderPageTS;
