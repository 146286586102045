export const MAIN_URL = `${process.env.REACT_APP_API_BASE_URL}`

export const defaultOrderStatus = {
  OPEN: 'OPEN',
  ACCEPTED: 'ACCEPTED',
  INPROGRESS: 'INPROGRESS',
  DELIVERED: 'DELIVERED',
}

export const userFields = (t) => [
  {
    title: t('orderPage.description.name'),
    field: 'customerName',
  },
  {
    title: t('orderPage.description.phone'),
    field: 'customerPhone',
  },
  {
    title: t('orderPage.description.comment'),
    field: 'comment',
  },
]

export const statusFields = (t) => [
  {
    name: 'OPEN',
    description: t('orderPage.statusList.open'),
  },
  {
    name: 'ACCEPTED',
    description: t('orderPage.statusList.accepted'),
  },
  {
    name: 'INPROGRESS',
    description: t('orderPage.statusList.inprogress'),
  },
  {
    name: 'DELIVERED',
    description: t('orderPage.statusList.delivered'),
  },
]

export const requestErrorsKeys = {
  serverError: 'serverError',
  invalidAuthUserData: 'invalidAuthUserData',
  orderNotFound: 'orderNotFound',
  orderCreationFailure: 'orderCreationFailure',
  pageNotFound: 'pageNotFound',
  otherError: 'otherError',
}

export const validationErrorsKeys = {
  valueMissing: 'valueMissing',
  tooShort: 'tooShort',
  patternMismatch: 'patternMismatch',
  typeMismatch: 'typeMismatch',
}

export const requestErrorMessages = (t) => ({
  serverError: () => ({
    title: t('errors.request.serverError.title'),
    description: t('errors.request.serverError.description'),
  }),
  invalidAuthUserData: () => ({
    title: t('errors.request.invalidAuthUserData.title'),
    description: t('errors.request.invalidAuthUserData.description'),
  }),
  orderCreationFailure: () => ({
    title: t('errors.request.orderCreationFailure.title'),
    description: t('errors.request.orderCreationFailure.description'),
  }),
  orderNotFound: () => ({
    title: t('errors.request.orderNotFound.title'),
    description: t('errors.request.orderNotFound.description'),
  }),
  otherError: () => ({
    title: t('errors.request.otherError.title'),
    description: t('errors.request.otherError.description'),
  }),
  pageNotFound: () => ({
    title: t('errors.request.pageNotFound.title'),
    description: t('errors.request.pageNotFound.description'),
  }),
})

export const validationErrorMessages = (t) => ({
  valueMissing: () => t('errors.validation.valueMissing'),
  tooShort: ({ minLength }) =>
    `${t('errors.validation.tooShort')}: ${minLength}`,
  patternMismatch: () => t('errors.validation.patternMismatch'),
  typeMismatch: () => t('errors.validation.typeMismatch'),
})
