import React, { memo, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { currentUserSelector } from 'redux/selectors'
import { checkToken } from 'redux/actions'
import { Footer } from 'components'

import './MainLayout.css'

const MainLayout = () => {
  const dispatch = useDispatch()
  const { loggedIn } = useSelector(currentUserSelector)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (loggedIn && location.pathname === '/signin') {
      navigate('/')
    }
  }, [location.pathname, loggedIn, navigate])

  useEffect(() => {
    if (loggedIn === null) {
      dispatch(checkToken())
    }
  }, [dispatch, loggedIn, location, navigate])

  if (loggedIn === null) {
    return null
  }

  return (
    <div className="main-layout">
      <Outlet />
      <Footer />  
    </div>
  )
}

export default memo(MainLayout)
