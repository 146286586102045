import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { currentUserSelector } from 'redux/selectors'

function ProtectedRoute() {
  const { loggedIn } = useSelector(currentUserSelector)

  if (!loggedIn) {
    return <Navigate to="/signin" />
  }

  return <Outlet />
}

export default memo(ProtectedRoute)
