import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { validationErrorsKeys, validationErrorMessages } from '../constants'

const useValidation = (initialState = { values: {}, isFormValid: true }) => {
  const [values, setValues] = useState(initialState.values)
  const [errorsKeys, setErrorsKeys] = useState({})
  const [isFormValid, setIsFormValid] = useState(initialState.isFormValid)
  const [minLengths, setMinLengths] = useState({})
  const [errors, setErrors] = useState({})

  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (Object.keys(errorsKeys).length) {
      const translatedErrors = Object.entries(errorsKeys).reduce(
        (acc, [inputName, errorKey]) => {
          if (!errorKey) {
            return acc
          }

          const message = validationErrorMessages(t)[errorKey]({
            minLength: minLengths[inputName],
          })

          return {
            ...acc,
            [inputName]: message,
          }
        },
        {}
      )

      setErrors(translatedErrors)
    }
  }, [errorsKeys, i18n.language, minLengths, t])

  const handleChange = (event) => {
    const input = event.target
    const { name, value, minLength, validity: validityState } = input
    let errorMessage

    if (!validityState.valid) {
      ;[, errorMessage] = Object.entries(validationErrorsKeys).find(
        ([, errorKey]) => {
          const hasError = validityState[errorKey]

          if (hasError) {
            return errorKey
          }

          return undefined
        }
      )
    }

    setValues({ ...values, [name]: value })
    setErrorsKeys({ ...errorsKeys, [name]: errorMessage })
    setMinLengths({ ...minLengths, [name]: minLength })
    setIsFormValid(input.closest('form').checkValidity())
  }

  const resetForm = useCallback(
    (newValues = {}, newErrors = {}, newIsFormValid = true) => {
      setValues(newValues)
      setErrorsKeys(newErrors)
      setErrors(newErrors)
      setIsFormValid(newIsFormValid)
    },
    [setValues, setErrorsKeys, setErrors, setIsFormValid]
  )

  return {
    values,
    setValues,
    handleChange,
    errors,
    isFormValid,
    setIsFormValid,
    resetForm,
  }
}

export default useValidation
