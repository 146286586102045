import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { currentOrderSelector } from 'redux/selectors'
import { getOrderById } from 'redux/actions'
import { defaultOrderStatus, userFields, statusFields } from 'utils/constants'
import { Header, Preloader } from 'components'
import { NotFoundPage } from 'pages'

import './OrderDetailsPage.css'

const OrderDetailsPage = (props) => {
  const { t } = useTranslation()
  const { id: orderId } = useParams()
  const dispatch = useDispatch()

  const fields = useMemo(() => userFields(t), [t])
  const statuses = useMemo(() => statusFields(t), [t])

  useEffect(() => {
    dispatch(getOrderById({ orderId }))
  }, [orderId, dispatch])

  const { order, loading, errorKey } = useSelector(currentOrderSelector)

  const renderOrderDetails = () =>
    fields.map(({ title, field }, index) => {
      return (
        <li
          className="order-list__item"
          key={`order-item_${index}`}
          data-name={`order-item-${index}`}
        >
          <h3 className="order-list__title">{title}</h3>
          <span className="order-list__description">{order[field]}</span>
        </li>
      )
    })

  const renderStatusDetails = () =>
    statuses.map(({ name, description }, index) => {
      return (
        <li
          className="status-list__item"
          key={`status-item_${index}`}
          data-name={`status-item-${index}`}
        >
          <div className="status-list__info">
            <span
              className={`status-list__status ${
                order.status === defaultOrderStatus[name] &&
                'status-list__status_active'
              }`}
            >
              {name}
            </span>
            <h3
              className={`status-list__description ${
                order.status === defaultOrderStatus[name] &&
                'status-list__description_active'
              }`}
            >
              {description}
            </h3>
          </div>
          <div className="status-list__line"></div>
        </li>
      )
    })

  if (errorKey) {
    return <NotFoundPage errorKey={errorKey} />
  }

  if (loading || !order) {
    return (
      <div className="order-details-page">
        <Preloader />
      </div>
    )
  }

  return (
    <div className="order-details-page">
      <Header />
      <main className="order-details">
        <input
          className="order-details__input"
          data-name={'useless-input'}
        ></input>
        <div className="order-details__content">
          <ul className="order-details__order-list order-list">
            {renderOrderDetails()}
          </ul>
          <ul className="order-details__status-list status-list">
            {renderStatusDetails()}
          </ul>
        </div>
      </main>
    </div>
  )
}

export default OrderDetailsPage
