import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as NotFoundImage } from 'images/order-not-found.svg'
import { requestErrorMessages, requestErrorsKeys } from 'utils/constants'
import { Header } from 'components'

import './NotFoundPage.css'

const NotFoundPage = ({ errorKey = requestErrorsKeys.pageNotFound }) => {
  const { t, i18n } = useTranslation()
  const [error, setError] = useState(null)

  useEffect(() => {
    if (errorKey) {
      setError(requestErrorMessages(t)[errorKey]())
    }
  }, [i18n.language, t, errorKey])

  if (!errorKey) {
    return null
  }

  return (
    <div className="not-found-page">
      <Header hideTitle />
      <main className="not-found" data-name={'orderNotFound-container'}>
        <h1 className="not-found__title">{error?.title}</h1>
        {error?.description && (
          <span className="not-found__description">{error.description}</span>
        )}
        <NotFoundImage className="not-found__image" />
      </main>
    </div>
  )
}

export default NotFoundPage
