import { useCallback, useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { validationErrorsKeys, validationErrorMessages } from '../constants';

interface ValidationErrorMessages {
  [key: string]: (params: { minLength: number }) => string;
}

interface ValidationErrorsKeys {
  [key: string]: string;
}

// Определение интерфейса для состояния формы заказа
interface OrderFormState {
  name: string;
  phone: string;
  comment: string;
}

// Определение интерфейса для начального состояния хука useValidation
interface UseValidationState<T> {
  initialValues: T;
  isFormValid: boolean;
}

// Определение интерфейса для возвращаемых значений хука useValidation
interface UseValidationReturn<T> {
  values: T;
  setValues: (values: T) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  errors: ValidationErrorsKeys;
  isFormValid: boolean;
  setIsFormValid: (isFormValid: boolean) => void;
  resetForm: (newValues?: T, newErrors?: ValidationErrorsKeys, newIsFormValid?: boolean) => void;
}

const useValidationTS = <T extends { [key: string]: any }>(
  initialState: UseValidationState<T>
): UseValidationReturn<T> => {
  const [values, setValues] = useState<T>(initialState.initialValues);
  const [errorsKeys, setErrorsKeys] = useState<ValidationErrorsKeys>({});
  const [isFormValid, setIsFormValid] = useState(initialState.isFormValid);
  const [minLengths, setMinLengths] = useState<{ [key: string]: number }>({});
  const [errors, setErrors] = useState<ValidationErrorsKeys>({});

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (Object.keys(errorsKeys).length) {
      const translatedErrors = Object.entries(errorsKeys).reduce(
        (acc, [inputName, errorKey]) => {
          if (!errorKey) {
            return acc;
          }

          const message = validationErrorMessages(t)[errorKey]({
            minLength: minLengths[inputName],
          });

          return {
            ...acc,
            [inputName]: message,
          };
        },
        {} as ValidationErrorsKeys
      );

      setErrors(translatedErrors);
    }
  }, [errorsKeys, i18n.language, minLengths, t]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    const { name, value, minLength, validity: validityState } = input;
    let errorMessage: string | undefined;

    if (!validityState.valid) {
      const errorEntry = Object.entries(validationErrorsKeys).find(
        ([, errorKey]) => validityState[errorKey]
      );
      errorMessage = errorEntry ? errorEntry[1] : undefined;
    }

    setValues({ ...values, [name]: value });
    setErrorsKeys({ ...errorsKeys, [name]: errorMessage || '' });
    setMinLengths({ ...minLengths, [name]: minLength });
    setIsFormValid(input.closest('form')?.checkValidity() ?? true);
  };

  const resetForm = useCallback(
    (newValues: T = {} as T, newErrors: ValidationErrorsKeys = {}, newIsFormValid: boolean = true) => {
      setValues(newValues);
      setErrorsKeys(newErrors);
      setErrors(newErrors);
      setIsFormValid(newIsFormValid);
    },
    [setValues, setErrorsKeys, setErrors, setIsFormValid]
  );

  return {
    values,
    setValues,
    handleChange,
    errors,
    isFormValid,
    setIsFormValid,
    resetForm,
  };
};

export default useValidationTS;