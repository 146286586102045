
import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { LoginPage, NewOrderPageTS, OrderDetailsPage, AllOrdersPage } from 'pages';

import { MainLayout, ProtectedRoute } from './layouts';
import { Preloader } from 'components';

const ExtendedComponent = () => (
  <Suspense fallback={<Preloader />}>
    <MainLayout />
  </Suspense>
);

const MainRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<ExtendedComponent />}>
        <Route element={<ProtectedRoute />}>
          <Route index element={<NewOrderPageTS />} />
          <Route path="order/:id" element={<OrderDetailsPage />} />
          <Route path="all-orders" element={<AllOrdersPage />} />
        </Route>
        <Route path="/signin" element={<LoginPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>
);

export default MainRouter;
