import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'

import MainRouter from 'router/MainRouter'
import reducers from './redux/reducers/'

import './i18n'
import './index.css'

const store = createStore(reducers, applyMiddleware(thunk))

ReactDOM.render(
  <Provider store={store}>
    <MainRouter />
  </Provider>,
  document.querySelector('#root')
)
