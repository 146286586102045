import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allOrdersSelector } from 'redux/selectors';
import { getAllOrders } from 'redux/actions';
import { OrderTable } from 'components';

const AllOrdersPage = () => {
  const dispatch = useDispatch();
  const { allOrders, loading, error } = useSelector(allOrdersSelector);

  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <OrderTable allOrders={allOrders || []} />
    </div>
  );
};

export default AllOrdersPage;
